import React from 'react'
import styles from '../Checkout.module.scss'
import Button from '@material-ui/core/Button'
import { purchase } from '../../../store/actions'
import { getToken } from '../utils/functions'

type stepperNavigatorType = {
   steps: string[]
   activeStep: number
   handleNext: () => void
   paymentMethodSelect: string
   addressDrawerOpen: boolean
   creditCardPaymentInfo: {
      transaction_amount: number;
      token: string;
      description: string;
      installments: string;
      payment_method_id: string;
      payer: {
         email: any;
      };
   }
   setCreditCardPaymentInfo: React.Dispatch<React.SetStateAction<{
      transaction_amount: number;
      token: string;
      description: string;
      installments: string;
      payment_method_id: string;
      payer: {
         email: any;
      };
   }>>
   setCardVisible: React.Dispatch<React.SetStateAction<boolean>>
   billetPaymentInfo: any
   setBilletPaymentInfo: any
   userAuth: {
      auth: boolean
      token: string
   }
   addresses: {
      firstname: string
      lastname: string
      street: string[]
      city: string
      region: {
         region_code: number
      }
      postcode: string
      telephone: string
   }[]
   selectedAddress: number
   userData: any
   editAddressDrawerOpen: any
   shipmentMethod: any
   fretePrices: any
   setErrorsCreditCardForm: any
   cart: any


}

const stepperNavigator: React.FC<stepperNavigatorType> = ({ selectedAddress, userAuth, addresses, steps, activeStep,
   handleNext, paymentMethodSelect, addressDrawerOpen, creditCardPaymentInfo,
   setCreditCardPaymentInfo, setCardVisible, billetPaymentInfo, setBilletPaymentInfo, userData, shipmentMethod, editAddressDrawerOpen, fretePrices, setErrorsCreditCardForm, cart }) => {
   return (
      <div className={styles.stepperNavigator}>
         <Button
            variant='contained'
            onClick={() => {
               if (activeStep === steps.length - 1) {
                  purchase(userAuth, userData.addresses.find(x => x.default_billing === true), addresses[selectedAddress], paymentMethodSelect, userData, creditCardPaymentInfo, billetPaymentInfo, fretePrices[0].prices.find(x => x.servico === shipmentMethod.toUpperCase()), cart);
               } else if ((activeStep === 2) && (paymentMethodSelect === 'creditcard')) {
                  getToken(creditCardPaymentInfo, setCreditCardPaymentInfo, setCardVisible, handleNext, setErrorsCreditCardForm);
               } else {
                  handleNext();
               }
            }}
            disabled={(activeStep === steps.length) || (activeStep === 2 && paymentMethodSelect === '') || (editAddressDrawerOpen !== null)}
            disableRipple
            className={styles.buttonStepper}
            classes={{ disabled: styles.buttonDisabled }}
         >
            {activeStep === steps.length - 1 ? 'Finalizar Compra' : 'Continuar'}
         </Button>
      </div>
   )
}

export default stepperNavigator
