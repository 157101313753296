import React from 'react'
import '../Checkout.scss'
import styles from '../Checkout.module.scss'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Menuitem from '@material-ui/core/MenuItem'

import InputMask from 'react-input-mask';

import regions from '../../../data/regions.json'

type newAddressType = {
   handleCepConsult: (cep: any, type: string) => void
   toggleEditAddressDrawer: (index: number | null) => void
   handleEditAddress: (label: string, value: any, id: number) => void
   saveEditAdress: (idx: number) => void
   editAddress: any
   idx: number
}

const editAddress: React.FC<newAddressType> = ({ handleCepConsult, toggleEditAddressDrawer, handleEditAddress, idx, saveEditAdress, editAddress }) => {

   return (
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '15px 15px 5px', borderBottom: '2px solid #f8f7f6'}}>
         <div style={{width: '100%', margin: '0 0 5px'}}>
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               placeholder={'Nome'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               type="text"
               value={editAddress.firstname}
               onChange={(e) => handleEditAddress('firstname', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{width: '100%', margin: '5px 0'}}>
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               placeholder={'Sobrenome'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               type="text"
               value={editAddress.lastname}
               onChange={(e) => handleEditAddress('lastname', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{width: '100%', margin: '5px 0'}}>
            <InputMask
               mask="99999-999"
               onChange={(e) => handleEditAddress('postcode', e.target.value, idx)}
               onBlur={(e) => handleCepConsult(e.target.value, 'edit-address')}
               value={editAddress.postcode}
               disabled={false}
               maskChar=" ">
               {
                  () =>
                     <TextField
                        classes={{ root: "textfieldFormAddresses" }}
                        id="postcode"
                        placeholder={'CEP'}
                        inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                        variant="outlined"
                        size="small"
                        type="text"
                        required
                        fullWidth
                     />
               }
            </InputMask>
         </div>
         <div style={{width: '100%', margin: '5px 0'}}>
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               id="street"
               placeholder={'Rua'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               value={editAddress.street[0]}
               type="text"
               onChange={(e) => handleEditAddress('street', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{width: '78%', margin: '5px 5px 5px 0'}}>
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               placeholder={'Complemento'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               value={editAddress.street[3] !== undefined ? editAddress.street[3] : ""}
               onChange={(e) => handleEditAddress('complement', e.target.value, idx)}
               type="text"
               required
               fullWidth
            />
         </div>
         <div style={{width: '20%', margin: '5px 0'}}>
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               id="number"
               placeholder={'N°'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               type="text"
               required
               fullWidth
               value={editAddress.street[1]}
               onChange={(e) => handleEditAddress('number', e.target.value, idx)}
            />
         </div>
         <div style={{width: '100%', margin: '5px 0'}}>
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               id="block"
               value={editAddress.street[2]}
               placeholder={'Bairro'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               type="text"
               onChange={(e) => handleEditAddress('block', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{width: '100%', margin: '5px 0'}}>
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               id="city"
               placeholder={'Cidade'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               value={editAddress.city}
               variant="outlined"
               size="small"
               type="text"
               onChange={(e) => handleEditAddress('city', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{width: '100%', margin: '5px 0'}}>
            <TextField
               select
               classes={{ root: "textfieldFormAddresses" }}
               size="small"

               value={editAddress.region.region_id.toString()}
               onChange={(e, child: any) => { handleEditAddress('region', child.props, idx) }}

               variant="outlined"
               fullWidth
            >
               {regions.map((region, idx) => {
                  return (
                     <Menuitem
                        classes={{ selected: styles.selectedItem }}
                        className={styles.menuItem}
                        id={region.code}
                        key={region.id}
                        value={region.id}>{region.name}
                     </Menuitem>
                  )
               })}
            </TextField>
         </div>
         <div style={{width: '100%', margin: '5px 0 15px'}}>
            <InputMask
               mask="(99) 9 9999-9999"
               onChange={(e) => handleEditAddress('telephone', e.target.value, idx)}
               value={editAddress.telephone}
               disabled={false}
               maskChar=" ">
               {
                  () =>
                     <TextField
                        classes={{ root: "textfieldFormAddresses" }}
                        id="telephone"
                        placeholder={'Telefone'}
                        inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                        variant="outlined"
                        size="small"
                        type="text"
                        required
                        fullWidth
                     />
               }
            </InputMask>
         </div>

         <div style={{ display: 'flex', justifyContent: 'center', width:'100%' }}>
            <Button onClick={() => saveEditAdress(idx)} variant='outlined' color='primary' style={{ textTransform: 'none', width: '55%', fontWeight: 'bold' }}>Salvar alterações</Button>
         </div>
         <div style={{ display: 'flex', justifyContent: 'center', width:'100%' }}>
            <Button onClick={() => toggleEditAddressDrawer(idx)} variant='text' color='primary' style={{ textTransform: 'none', width: '40%' }}>Cancelar</Button>
         </div>
      </div>
   )
}

export default editAddress