import React from "react"

import { useSelector } from 'react-redux'
import Login from '../Login/Login'

type PrivateRouteType = {
  component: any
  path: any
}

const PrivateRoute: React.FC<PrivateRouteType> = ({ component: Component, path, ...rest }) => {

  const userAuth = useSelector((state: any) => state.userAuth);

  if (!userAuth.auth) {
    // If we’re not logged in, redirect to the home page.
    //console.log(userAuth.auth);
    return <Login />
  }

  return <Component {...rest} />
}



export default PrivateRoute