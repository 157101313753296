import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from './Checkout.module.scss'
import { Link } from 'gatsby'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import classNames from 'classnames/bind'
import Container from '@material-ui/core/Container'
import StepperComponent from './components/stepperComponent'
import StepperNavigator from './components/stepperNavigator'
import Address from './components/checkoutAddress'
import Shipment from './components/shipment'
import Payment from './components/paymentMethods'
import Resume from './components/checkoutResume'
import DrawerCartResume from '../Drawers/DrawerCartResume/DrawerCartResume'
import { MICROSERVICE_CORREIOS, MICROSERVICE_USER, MAGENTO_URL } from '../../../userglobals'
import { changeCustomerData, setLoadingTrue, setLoadingFalse, setBilletUrl } from '../../store/actions'
import regions from '../../data/regions.json'
import loadGMaps from './utils/scripts'


let cx = classNames.bind(styles);


type CheckoutType = {

}

const Checkout: React.FC<CheckoutType> = () => {
   const userAuth = useSelector((state: any) => state.userAuth);
   const userData = useSelector((state: any) => state.userData);
   const cart = useSelector((state: any) => state.cart);
   const totalPrice = useSelector((state: any) => state.totalPrice);
   const quoteId = useSelector((state: any) => state.quoteId);
   const [activeStep, setActiveStep] = useState(0);
   const steps = ['Endereço', 'Envio', 'Pagamento', 'Resumo'];
   const [token, setToken] = useState("");
   const [shipmentMethod, setShipmentMethod] = useState('PAC');
   const [addressDrawerOpen, setAddressDrawerOpen] = useState(false);
   const [editAddressDrawerOpen, setEditAddressDrawerOpen] = useState<null | number>(null);
   const [paymentMethodSelect, setPaymentMethodSelect] = useState('');
   const [cardVisible, setCardVisible] = useState(true);
   const [editAddress, setEditAddress] = useState<any>(null);
   const [addresses, setAddresses] = useState([userData.addresses.find((x: { default_shipping: boolean }) => x.default_shipping === true)]);
   const [openTooltipCVV, setOpenTooltipCVV] = useState(false);
   const [selectedAddress, setSelectedAddress] = useState(0);
   const [fretePrices, setFretePrices] = useState([]);
   const [cardNumber, setCardNumber] = useState("");
   const [transactionAmount, setTransactionAmount] = useState(totalPrice)
   const [creditCardPaymentInfo, setCreditCardPaymentInfo] = useState({
      transaction_amount: parseFloat(totalPrice),
      token: "",
      description: `${quoteId}`,
      installments: '1',
      payment_method_id: "",
      issuer_id: "",
      payer: {
         email: userData.email,
         identification: {
            type: 'CPF',
            number: '11111111111'
         },
      },
      additional_info: {
         items: cart.map((item: any) => (
            {
               id: JSON.stringify(item.visibleCartItem.id),
               title: item.visibleCartItem.name,
               picture_url: MAGENTO_URL + 'media/catalog/product' + item.visibleCartItem.image,
               quantity: Number(item.visibleCartItem.qty),
               unit_price: item.visibleCartItem.price
            }
         )),
         payer: {
            first_name: userData.firstname,
            last_name: userData.lastname,
            address: {
               zip_code: userData.addresses.find((x: { default_billing: boolean }) => x?.default_billing === true).postcode,
               street_name: userData.addresses.find((x: { default_billing: boolean }) => x?.default_billing === true).street[0],
               street_number: (+userData.addresses.find((x: { default_billing: boolean }) => x?.default_billing === true).street[1])
            },
            // registration_date: userData.created_at,
            phone: {
               area_code: userData.addresses.find((x: { default_billing: boolean }) => x?.default_billing === true).telephone.substr(1, 2),
               number: userData.addresses.find((x: { default_billing: boolean }) => x?.default_billing === true).telephone.substr(5, userData.addresses.find((x: { default_billing: boolean }) => x?.default_billing === true).telephone.length)
            }
         },
         // shipments: {
         //    street_name: userData.addresses.find((x: { default_shipping: boolean }) => x?.default_shipping === true).street[0],
         //    street_number: (+userData.addresses.find((x: { default_shipping: boolean }) => x?.default_shipping === true).street[1]),
         //    zip_code: userData.addresses.find((x: { default_shipping: boolean }) => x?.default_shipping === true).postcode,
         //    city_name: userData.addresses.find((x: { default_shipping: boolean }) => x?.default_shipping === true).city,
         //    state_name: userData.addresses.find((x: { default_shipping: boolean }) => x?.default_shipping === true).region.region
         // }
      }
   });

   const [formPaymentInfo, setFormPaymentInfo] = useState({
      cardholderName: "",
      cardExpirationMonth: "",
      cardExpirationYear: "",
      securityCode: "",
      docNumber: ""
   });

   const [billetPaymentInfo, setBilletPaymentInfo] = useState({
      transaction_amount: parseFloat(totalPrice),
      description: `${quoteId}`,
      payment_method_id: 'bolbradesco',
      payer: {
         email: userData.email,
         first_name: userData.firstname,
         last_name: userData.lastname,
         identification: {
            type: 'CPF',
            number: '11111111111'
         },
         address: {
            zip_code: userData.addresses[0].postcode,
            street_name: userData.addresses[0].street[0],
            street_number: userData.addresses[0].street[1],
            neighborhood: userData.addresses[0].street[2],
            city: userData.addresses[0].city,
            federal_unit: userData.addresses[0].region.region_code
         }
      }
   });

   const [installments, setInstallments] = useState([
      {
         installment_amount: creditCardPaymentInfo.transaction_amount,
         installments: 1,
         recommended_message: `1 parcela de R$ ${creditCardPaymentInfo.transaction_amount}`
      }
   ]);
   const [cartResumeOpen, setCartResumeOpen] = useState(false);
   const [loaded, setLoaded] = useState(false);
   const [errorsCreditCardForm, setErrorsCreditCardForm] = useState([]);

   useEffect(() => {
      async function setKeys() {
         loadGMaps(() => {
            setLoaded(true);
            window.Mercadopago.setPublishableKey("TEST-85af46dc-e151-4442-82fa-178bb447bfca");
            window.Mercadopago.getIdentificationTypes();
         });
      }
      setKeys();
   }, []);

   useEffect(() => {
      setBilletUrl("");
      const getData = async () => {
         return Promise.all(addresses.map(async (address: any, idx: number) => {
            const payload = {
               sCepOrigem: "07252971",
               sCepDestino: address.postcode.replace(/[^\w\s]/gi, ''),
               nVlPeso: "0.1",
               nCdFormato: 1,
               nVlComprimento: 16,
               nVlAltura: 7,
               nVlLargura: 11,
               nVlDiametro: 0,
            }
            return axios.post(MICROSERVICE_CORREIOS + 'calc-ship-price', payload);
         }))
      }
      getData().then(result => {

         const frete = result.map((x: any) => x.data);
         const fretes = addresses.map((x: any, idx: number) => {
            return ({ cep: x.postcode, prices: frete[idx] })
         })
         setFretePrices(fretes)
      })
   }, []);

   useEffect(() => {
      if (fretePrices.length > 0) {
         const newTransactionAmount = +((parseFloat((+totalPrice) + (+(fretePrices[0]?.prices.find((x: any) => x.servico === shipmentMethod.toUpperCase())?.Valor)?.replace(',', '.')))).toFixed(2));
         const copyCreditCardPaymentInfo = { ...creditCardPaymentInfo };
         copyCreditCardPaymentInfo.transaction_amount = newTransactionAmount;
         setCreditCardPaymentInfo(copyCreditCardPaymentInfo);
         const copyBilletPaymentInfo = { ...billetPaymentInfo };
         copyBilletPaymentInfo.transaction_amount = newTransactionAmount;
         setBilletPaymentInfo(copyBilletPaymentInfo);
         const copyInstallments = [...installments];
         copyInstallments[0].installment_amount = newTransactionAmount;
         copyInstallments[0].recommended_message = `1 parcela de R$ ${newTransactionAmount}`
         setInstallments(copyInstallments);
         setTransactionAmount(newTransactionAmount);
      }
   }, [shipmentMethod, fretePrices, totalPrice])

   const handleTooltipCVVClose = () => {
      setOpenTooltipCVV(false);
   };

   const handleTooltipCVVOpen = () => {
      setOpenTooltipCVV(true);
   };

   const deleteAddress = (id: number, index: number) => {
      //console.log(id)
      if (id !== undefined) {
         axios.post(MICROSERVICE_USER + 'delete-customer-address', { ...userAuth, id })
            .then(response => {
               setSelectedAddress(0)
               setAddresses(response.data)

            })
      } else {
         const copyAddresses = [...addresses];
         copyAddresses.splice(index, 1);
         setSelectedAddress(0)
         setAddresses(copyAddresses)

      }

   }

   const toggleResumeCart = (bool: boolean) => {
      if (bool) {
         setCartResumeOpen(true);
      } else {
         setCartResumeOpen(false)
      }
   }

   const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };

   const handleStep = (step: number) => () => {
      setActiveStep(step);
   };

   const handleShipmentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
      setShipmentMethod((event.target as HTMLInputElement).value);
      const newTransactionAmount = parseFloat(totalPrice) + parseFloat((fretePrices[0].prices.find((x: any) => x.servico === event.target.value.toUpperCase()).Valor).replace(',', '.'))
      const copyCreditCardPaymentInfo = { ...creditCardPaymentInfo };
      copyCreditCardPaymentInfo.transaction_amount = newTransactionAmount;
      setCreditCardPaymentInfo(copyCreditCardPaymentInfo);
      const copyBilletPaymentInfo = { ...billetPaymentInfo };
      copyBilletPaymentInfo.transaction_amount = newTransactionAmount;
      setBilletPaymentInfo(copyBilletPaymentInfo);
      const copyInstallments = [...installments];
      copyInstallments[0].installment_amount = newTransactionAmount;
      copyInstallments[0].recommended_message = `1 parcela de R$ ${newTransactionAmount}`
      setInstallments(copyInstallments);
      setTransactionAmount(newTransactionAmount)
   };

   const toggleAddressDrawer = (bool: boolean) => {

      if (bool) {
         setAddressDrawerOpen(true);

      } else {
         setAddressDrawerOpen(false);
      }

   }

   const toggleEditAddressDrawer = (index: number | null) => {
      if (editAddressDrawerOpen === index) {
         setEditAddressDrawerOpen(null)
      } else if (index !== null) {
         setEditAddress(addresses[index])
         setEditAddressDrawerOpen(index);
      }
   }
   const calcRastreio = (cep: string, idx: number) => {
      const payload = {
         sCepOrigem: "07252971",
         sCepDestino: cep.replace(/[^\w\s]/gi, ''),
         nVlPeso: "0.1",
         nCdFormato: 1,
         nVlComprimento: 16,
         nVlAltura: 7,
         nVlLargura: 11,
         nVlDiametro: 0,
      }
      axios.post(MICROSERVICE_CORREIOS + 'calc-ship-price', payload)
         .then(response => {
            const copyFretePrices = [...fretePrices];
            copyFretePrices[idx] = { cep: cep.replace(/[^\w\s]/gi, ''), prices: response.data }
            setFretePrices(copyFretePrices)
         })
   }

   const saveEditAdress = (idx: any) => {
      const copyUserData = JSON.parse(JSON.stringify(userData));
      const shippingId = copyUserData.addresses.findIndex((x: any) => x.id == (+copyUserData.default_shipping))

      copyUserData.addresses[shippingId] = editAddress;
      //console.log(copyUserData)

      // erro 404 POST

      axios.post(MICROSERVICE_USER + "edit-customer-data", { ...userAuth, customer: copyUserData })
         .then(response => {
            calcRastreio(editAddress.postcode, shippingId)
            setAddresses([response.data.addresses[shippingId]])
            changeCustomerData(response.data);
            setEditAddressDrawerOpen(null);
         })
         .catch(err => {
            console.log('erro')
         })
   }

   const closeNewAdressDrawer = () => {
      setAddressDrawerOpen(false);
   }

   const handleEditAddress = (label: string, value: any, id: number) => {
      const copyAddress = { ...editAddress };

      switch (label) {

         case 'street': {
            const copyStreet = [...copyAddress.street]
            copyStreet[0] = value
            copyAddress.street = copyStreet
         }
            break;
         case 'number': {
            const copyStreet = [...copyAddress.street]
            copyStreet[1] = value
            copyAddress.street = copyStreet
         }
            break;
         case 'block': {
            const copyStreet = [...copyAddress.street]
            copyStreet[2] = value
            copyAddress.street = copyStreet
         }
            break;
         case 'complement': {
            const copyStreet = [...copyAddress.street]
            copyStreet[3] = value
            copyAddress.street = copyStreet
         }
            break;
         case 'region':
            copyAddress.region = {
               region_code: value.id,
               region: value.children,
               region_id: parseInt(value.value)
            }
            copyAddress.region_id = parseInt(value.value)
            break;
         default:
            copyAddress[label] = value
            break;
      }
      setEditAddress(copyAddress)
   }

   const handleSelectedAddress = (id: number) => {
      setSelectedAddress(id)
   }

   const handleCepConsult = (cep: any, type: string) => {
      //console.log(type)

      axios.post(MICROSERVICE_CORREIOS + 'check-cep', { cep })
         .then(response => {
            //console.log(response.data)
            if (response.data.Erro === 404 || response.data.erro === true) {
               //console.log('error')
               const copyEditAddress = { ...editAddress }
               copyEditAddress.postcode = ''
               copyEditAddress.street[0] = ''
               copyEditAddress.street[3] = ''
               copyEditAddress.street[2] = ''
               copyEditAddress.city = ''
               // toggleRastreioAlert(true)
               setEditAddress(copyEditAddress)
            } else {
               const copyEditAddress = { ...editAddress };
               copyEditAddress.street[0] = response.data?.logradouro
               copyEditAddress.street[3] = response.data?.complemento
               copyEditAddress.street[2] = response.data?.bairro
               copyEditAddress.city = response.data?.localidade
               const region = regions.find(x => x.code === response.data?.uf)
               copyEditAddress.region = { region_code: region?.code, region: region?.name, region_id: parseInt(region.id) }
               setEditAddress(copyEditAddress)

            }
         })
   }

   const handlePaymentMethodChange = (e: { target: { value: React.SetStateAction<string> } }) => {
      setPaymentMethodSelect(e.target.value);
   };

   return (
      <Container className={styles.container}>
         <StepperComponent
            steps={steps}
            activeStep={activeStep}
            handleStep={handleStep}
         />
         <DrawerCartResume
            cartResumeOpen={cartResumeOpen}
            toggleResumeCart={toggleResumeCart}
         />
         <Address
            cx={cx}
            activeStep={activeStep}
            addresses={addresses}
            handleSelectedAddress={handleSelectedAddress}
            selectedAddress={selectedAddress}
            editAddress={editAddress}
            editAddressDrawerOpen={editAddressDrawerOpen}
            toggleEditAddressDrawer={toggleEditAddressDrawer}
            handleEditAddress={handleEditAddress}
            saveEditAdress={saveEditAdress}
            handleCepConsult={handleCepConsult}
            toggleResumeCart={toggleResumeCart}
            deleteAddress={deleteAddress}
         />
         <Shipment
            cx={cx}
            activeStep={activeStep}
            calculatedPrice={fretePrices[selectedAddress]}
            shipmentMethod={shipmentMethod}
            handleShipmentMethod={handleShipmentMethod}
         />
         <Payment
            cx={cx}
            activeStep={activeStep}
            paymentMethodSelect={paymentMethodSelect}
            handlePaymentMethodChange={handlePaymentMethodChange}
            creditCardPaymentInfo={creditCardPaymentInfo}
            setCreditCardPaymentInfo={setCreditCardPaymentInfo}
            installments={installments}
            setInstallments={setInstallments}
            cardVisible={cardVisible}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            formPaymentInfo={formPaymentInfo}
            setFormPaymentInfo={setFormPaymentInfo}
            openTooltipCVV={openTooltipCVV}
            handleTooltipCVVClose={handleTooltipCVVClose}
            handleTooltipCVVOpen={handleTooltipCVVOpen}
            errorsCreditCardForm={errorsCreditCardForm}
         />
         <Resume
            cx={cx}
            activeStep={activeStep}
            paymentMethodSelect={paymentMethodSelect}
            fretePrices={fretePrices}
            shipmentMethod={shipmentMethod}
            calculatedPrice={fretePrices[selectedAddress]}
            handleStep={handleStep}
            addresses={addresses}
            selectedAddress={selectedAddress}
            totalPrice={totalPrice}
            transactionAmount={transactionAmount}
         />
         <StepperNavigator
            selectedAddress={selectedAddress}
            userAuth={userAuth}
            addresses={addresses}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            paymentMethodSelect={paymentMethodSelect}
            addressDrawerOpen={addressDrawerOpen}
            creditCardPaymentInfo={creditCardPaymentInfo}
            setCreditCardPaymentInfo={setCreditCardPaymentInfo}
            billetPaymentInfo={billetPaymentInfo}
            setBilletPaymentInfo={setBilletPaymentInfo}
            setCardVisible={setCardVisible}
            userData={userData}
            editAddressDrawerOpen={editAddressDrawerOpen}
            shipmentMethod={shipmentMethod}
            fretePrices={fretePrices}
            setErrorsCreditCardForm={setErrorsCreditCardForm}
            cart={cart}
            token={token}
            setToken={setToken}
         />
      </Container>
   )
}

export default Checkout
