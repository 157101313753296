import React from 'react'
import styles from '../Checkout.module.scss'
import { ClassNamesFn } from 'classnames/types'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

type shipmentType = {
   cx: ClassNamesFn
   activeStep: number
   shipmentMethod: string
   handleShipmentMethod: (event: React.ChangeEvent<HTMLInputElement>) => void
   calculatedPrice: any
}

const shipment: React.FC<shipmentType> = ({ cx, activeStep, calculatedPrice, shipmentMethod, handleShipmentMethod }) => {
   const className = cx({
      displayBlock: activeStep === 1,
      mainDiv: activeStep === 1,
      displayNone: activeStep !== 1
   });

   const pacClass = cx({
      divSubtitle: true,
      selectedAddress: shipmentMethod === 'PAC'
   });

   const sedexClass = cx({
      divSubtitle: true,
      selectedAddress: shipmentMethod === 'Sedex'
   });

   if (calculatedPrice !== undefined) {
      const PAC = calculatedPrice.prices.find((x: { servico: string; }) => x.servico === 'PAC');
      const SEDEX = calculatedPrice.prices.find((x: { servico: string; }) => x.servico === 'SEDEX');


      return (
         <div className={className}>
            <div className={styles.divSubtitle}>
               <p className={styles.subtitlePayment}>Forma de envio</p>
            </div>
            <RadioGroup aria-label="shipment" name="shipmentmethods" value={shipmentMethod} onChange={handleShipmentMethod}>
               <div className={pacClass}>
                  <FormControlLabel
                     value={"PAC"}
                     //<div style={{ fontSize: '12px', width: '200px' }} dangerouslySetInnerHTML={{ __html: tooltipText }} />
                     control={<Radio size='small' color='primary' disableTouchRipple style={{ padding: '0 10px' }} />} label={<div dangerouslySetInnerHTML={{ __html: PAC.servico + ' - ' + PAC.PrazoEntrega + ' dias úteis <b>- R$' + PAC.Valor + '</b>'}} />} classes={{ label: styles.label }} />
               </div>
               <div className={sedexClass}>
                  <FormControlLabel value="Sedex" control={<Radio size='small' color='primary' disableTouchRipple style={{ padding: '0 10px' }} />} label={<div dangerouslySetInnerHTML={{ __html: SEDEX.servico + ' - ' + SEDEX.PrazoEntrega + ' dias úteis - <b>R$' + SEDEX.Valor + '</b>'}} />} classes={{ label: styles.label }} />
               </div>
            </RadioGroup>
         </div>
      )
   } else {
      return (null)
   }
}

export default shipment
