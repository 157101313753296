import React, { useState } from 'react'
import styles from '../DrawerCartResume.module.scss'
import Button from '@material-ui/core/Button'
import ImageComponent from '../../../ImageComponent/SingleImageComponent'
import { MAGENTO_URL } from '../../../../../userglobals'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import DeleteButton from './deleteButton'

type cartItemType = {
   item: any
   idx: number
   handleAddQty: (idx: number, item: any) => void
   handleSubtractQty: (idx: number, item: any) => void
   handleDeleteItem: (idx: number, item: any) => void
}

const cartItem: React.FC<cartItemType> = ({ item, idx, handleAddQty, handleSubtractQty, handleDeleteItem }) => {
   return (
      <div className={styles.cartItem} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
         <div style={{ maxWidth: '30%' }}>
            <ImageComponent
               imgUrl={MAGENTO_URL + 'media/catalog/product' + item.visibleCartItem.image + "?width=93&auto=webp&quality=100"}
               altImg={item.visibleCartItem.name}
               _class={styles.shadowImg}
            />
         </div>
         <div style={{ maxWidth: '65%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div className={styles.font13} style={{ maxWidth: '85%' }}>
               {item.visibleCartItem.name}
            </div>
            <div className={styles.gridTrash} style={{ maxWidth: '10%' }}>
               <DeleteButton handleDeleteItem={handleDeleteItem} idx={idx} item={item} />
            </div>
            {
               item.visibleCartItem.custom_options &&
               <div className={styles.gridCustomOptions} style={{ width: '95%' }}>
                  {item.visibleCartItem.custom_options!.map((customOption: { title: React.ReactNode; option_value: React.ReactNode; }, idx: number) => (
                     <div key={idx} className={styles.font12}>
                        {customOption.title}: <b>{customOption.option_value}</b> <br />
                     </div>
                  ))}
               </div>
            }
         </div>
         <div className={styles.gridQtyPrice} style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
               Quantidade:
               <Button onClick={() => handleSubtractQty(idx, item)} disabled={(+item.visibleCartItem.qty) === 1} variant='text' className={styles.buttonsHandleQty} classes={{ disabled: styles.buttonDisabled }}>
                  <RemoveRoundedIcon fontSize='small' />
               </Button>
               {item.visibleCartItem.qty}
               <Button onClick={() => handleAddQty(idx, item)} variant='text' className={styles.buttonsHandleQty}>
                  <AddRoundedIcon fontSize='small' />
               </Button>
            </div>
            <div className={styles.productPrice}>
               R${`${((+item.visibleCartItem.qty) * (item.visibleCartItem.price)).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}
            </div>
         </div>
      </div>
   )
}

export default cartItem
