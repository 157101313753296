import React from 'react'
import styles from '../Checkout.module.scss'
import TextField from '@material-ui/core/TextField'
import ImageComponent from '../../ImageComponent/SingleImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'
import { handlePaymentInfo, handleFormInfo } from '../utils/functions'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import InputMask from 'react-input-mask'

type creditCardFormType = {
   formPaymentInfo: {
      cardholderName: string
      cardExpirationMonth: string
      cardExpirationYear: string
      securityCode: string
      docNumber: string
   }
   setFormPaymentInfo: React.Dispatch<React.SetStateAction<{
      cardholderName: string;
      cardExpirationMonth: string;
      cardExpirationYear: string;
      securityCode: string;
      docNumber: string;
   }>>
   formCartao: string
   creditCardPaymentInfo: {
      transaction_amount: number,
      token: string,
      description: string,
      installments: string,
      payment_method_id: string
      payer: any
   }
   setCreditCardPaymentInfo: React.Dispatch<React.SetStateAction<{
      transaction_amount: number;
      token: string;
      description: string;
      installments: string;
      payment_method_id: string;
      payer: any
   }>>
   installments: {
      installment_amount: number;
      installments: number;
      recommended_message: string;
   }[]
   setInstallments: React.Dispatch<React.SetStateAction<{
      installment_amount: number;
      installments: number;
      recommended_message: string;
   }[]>>
   cardVisible: boolean
   cardNumber: string
   setCardNumber: React.Dispatch<React.SetStateAction<string>>
   openTooltipCVV: boolean
   handleTooltipCVVClose: () => void
   handleTooltipCVVOpen: () => void
   errorsCreditCardForm: any
}

const creditCardForm: React.FC<creditCardFormType> = ({
   formPaymentInfo,
   setFormPaymentInfo,
   formCartao,
   creditCardPaymentInfo,
   setCreditCardPaymentInfo,
   setInstallments,
   cardVisible,
   cardNumber,
   setCardNumber,
   installments,
   openTooltipCVV,
   handleTooltipCVVClose,
   handleTooltipCVVOpen,
   errorsCreditCardForm
}) => {

   const index205 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '205');
   const index208 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '208');
   const index209 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '209');
   const index220 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '220');
   const index221 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '221');
   const index224 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '224');
   const indexE301 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === 'E301');
   const indexE302 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === 'E302');
   const index316 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '316');
   const index325 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '325');
   const index326 = errorsCreditCardForm.findIndex((x: { code: string }) => x.code === '326');

   const tooltipText = "<b>CVV</b> é um <b>código de segurança</b> de 3 dígitos, impresso no verso de cartões de crédito.<br/> Importante: Em cartões American Express você encontra o CVV com 4 dígitos e na frente.";
   return (
      <div className={formCartao} style={{ flexWrap: 'wrap' }}>
         <ImageComponent
            imgUrl={MAGENTO_URL + 'media/icons/forma-de-pagamento-checkout.svg'}
            altImg={`creditcards`}
            heightContainer={30}
            widthContainer={'100%'}
            heightLoading={20}
            widthLoading={20}
         />
         <div style={{ width: '100%' }} className={styles.margin}>
            {index221 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[index221].description}</div> :
               index316 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[index316].description}</div> : null}
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               placeholder={'Nome do titular'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               error={errorsCreditCardForm.some((x: any) => x.textfield === 'cardholderName')}
               variant="outlined"
               size="small"
               type="text"
               autoComplete="off"
               name="cardholderName"
               value={formPaymentInfo.cardholderName}
               onChange={(e) => handleFormInfo(e, formPaymentInfo, setFormPaymentInfo)}
               onCopy={(e)=>{e.preventDefault()}}
               onPaste={(e)=>{e.preventDefault()}}
               onCut={(e)=>{e.preventDefault()}}
               onDrag={(e)=>{e.preventDefault()}}
               onDrop={(e)=>{e.preventDefault()}}
               required
               fullWidth
            />
         </div>
         {/* <div style={{ width: '100%' }} className={styles.margin}>
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               placeholder={'Número do documento'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               type="text"
               autoComplete="off"
               name="docNumber"
               value={formPaymentInfo.docNumber}
               onChange={(e) => handleFormInfo(e, formPaymentInfo, setFormPaymentInfo)}
               required
               fullWidth
            />
         </div> */}
         <div style={{ width: '100%' }} className={styles.margin}>
            {index205 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[index205].description}</div> :
               indexE301 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[indexE301].description}</div> : null}
               <InputMask
                  mask="9999 9999 9999 9999"
                  value={cardNumber}
                  onChange={(e) => handlePaymentInfo(e, creditCardPaymentInfo, setCreditCardPaymentInfo, setInstallments, cardNumber, setCardNumber)}
                  onCopy={(e)=>{e.preventDefault()}}
                  onPaste={(e)=>{e.preventDefault()}}
                  onCut={(e)=>{e.preventDefault()}}
                  onDrag={(e)=>{e.preventDefault()}}
                  onDrop={(e)=>{e.preventDefault()}}
                  autoComplete="off"
                  maskChar="">
               {
                  () =>
                     <TextField
                        classes={{ root: "textfieldFormAddresses" }}
                        placeholder={'Número do cartão'}
                        inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                        error={errorsCreditCardForm.some((x: any) => x.textfield === 'cardNumber')}
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="off"
                        name="cardNumber"
                        required
                        fullWidth
                     />}
            </InputMask>
         </div>
         <div style={{ width: '30%', marginRight: '10px' }} className={styles.margin}>
            {index208 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[index208].description}</div> :
               index326 !== -1 || index325 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[index325].description}</div> : null}
            <InputMask
               mask="99"
               value={formPaymentInfo.cardExpirationMonth}
               onChange={(e) => handleFormInfo(e, formPaymentInfo, setFormPaymentInfo)}
               onCopy={(e)=>{e.preventDefault()}}
               onPaste={(e)=>{e.preventDefault()}}
               onCut={(e)=>{e.preventDefault()}}
               onDrag={(e)=>{e.preventDefault()}}
               onDrop={(e)=>{e.preventDefault()}}
               autoComplete="off"
               maskChar="">
               {() =>
                  <TextField
                     classes={{ root: "textfieldFormAddresses" }}
                     placeholder={'Mês'}
                     inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                     error={errorsCreditCardForm.some((x: any) => x.textfield === 'cardExpirationMonth')}
                     variant="outlined"
                     size="small"
                     type="text"
                     autoComplete="off"
                     name="cardExpirationMonth"
                     required
                     fullWidth
                  />}
            </InputMask>
         </div>
         <div style={{ width: '30%' }} className={styles.margin}>
            {index209 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[index209].description}</div> :
               index326 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[index326].description}</div> : null}
            <InputMask
               mask="99"
               value={formPaymentInfo.cardExpirationYear}
               onChange={(e) => handleFormInfo(e, formPaymentInfo, setFormPaymentInfo)}
               onCopy={(e)=>{e.preventDefault()}}
               onPaste={(e)=>{e.preventDefault()}}
               onCut={(e)=>{e.preventDefault()}}
               onDrag={(e)=>{e.preventDefault()}}
               onDrop={(e)=>{e.preventDefault()}}
               autoComplete="off"
               maskChar="">
               {() =>
                  <TextField
                     classes={{ root: "textfieldFormAddresses" }}
                     placeholder={'Ano'}
                     inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                     error={errorsCreditCardForm.some((x: any) => x.textfield === 'cardExpirationYear')}
                     variant="outlined"
                     size="small"
                     type="text"
                     autoComplete="off"
                     name="cardExpirationYear"
                     required
                     fullWidth
                  />}
            </InputMask>
         </div>
         <div style={{ width: '60%' }} className={styles.margin}>
            {index224 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[index224].description}</div> :
               indexE302 !== -1 ? <div className={styles.erros}>{errorsCreditCardForm[indexE302].description}</div> : null}
            <InputMask
               mask="999"
               value={formPaymentInfo.securityCode}
               onChange={(e) => handleFormInfo(e, formPaymentInfo, setFormPaymentInfo)}
               onCopy={(e)=>{e.preventDefault()}}
               onPaste={(e)=>{e.preventDefault()}}
               onCut={(e)=>{e.preventDefault()}}
               onDrag={(e)=>{e.preventDefault()}}
               onDrop={(e)=>{e.preventDefault()}}
               autoComplete="off"
               maskChar="">
               {() =>
                  <TextField
                     classes={{ root: "textfieldFormAddresses" }}
                     placeholder={'Código de segurança'}
                     inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                     error={errorsCreditCardForm.some((x: any) => x.textfield === 'securityCode')}
                     variant="outlined"
                     size="small"
                     type="text"
                     autoComplete="off"
                     name="securityCode"
                     required
                     fullWidth
                  />
               }
            </InputMask>
         </div>
         <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
            <ClickAwayListener onClickAway={handleTooltipCVVClose}>
               <Tooltip
                  placement="top"
                  onClose={handleTooltipCVVClose}
                  open={openTooltipCVV}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  title={<div style={{ fontSize: '12px', width: '200px' }} dangerouslySetInnerHTML={{ __html: tooltipText }} />}
               >
                  <Button disableTouchRipple onClick={handleTooltipCVVOpen} color='primary' className={styles.buttonLabel} style={{ textTransform: 'none', padding: 0 }} classes={{ label: styles.buttonCVV }}>
                     O que é isso?
                     </Button>
               </Tooltip>
            </ClickAwayListener>
         </div>
         <div style={{ width: '100%' }} className={styles.margin}>
            <TextField
               select
               size="small"
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               classes={{ root: "textfieldFormAddresses" }}
               name="installments"
               value={creditCardPaymentInfo.installments.toString()}
               onChange={(e) => { handlePaymentInfo({ installments: e.target.value }, creditCardPaymentInfo, setCreditCardPaymentInfo, setInstallments, cardNumber, setCardNumber) }}
               onCopy={(e)=>{e.preventDefault()}}
               onPaste={(e)=>{e.preventDefault()}}
               onCut={(e)=>{e.preventDefault()}}
               onDrag={(e)=>{e.preventDefault()}}
               onDrop={(e)=>{e.preventDefault()}}
               variant="outlined"
               SelectProps={{
                  MenuProps: {
                     style: { maxHeight: '70vh' }
                  }
               }}
               fullWidth
            >
               {installments.map((installment: any, idx: any) => {
                  return (
                     <MenuItem
                        classes={{ selected: styles.selectedItem }}
                        className={styles.installmentsSelect}
                        key={installment.installments}
                        value={installment.installments.toString()}
                     >
                        {installment.recommended_message}
                     </MenuItem>
                  )
               })}
            </TextField>
         </div>
         <div style={{ width: '100%' }} className={styles.divHelperForm}>
            <span className={styles.helperForm}>Entre com os dados de seu cartão e os confira ao final.</span>
         </div>
         <div className={styles.displayNone}>
            <div>
               <form id="pay" name="pay" noValidate autoComplete="off">
                  <p>
                     <label htmlFor="description">Descrição</label>
                     <br />
                     <input
                        type="text"
                        name="description"
                        id="description"
                        value={creditCardPaymentInfo.description}
                        disabled
                     />
                  </p>
                  <p>
                     <label htmlFor="transaction_amount">Valor a pagar</label>
                     <br />
                     <input
                        name="transaction_amount"
                        id="transaction_amount"
                        value={creditCardPaymentInfo.transaction_amount}
                        disabled
                     />
                  </p>
                  <p>
                     <label htmlFor="cardNumber">Número do cartão</label>
                     <br />
                     <input
                        type="text"
                        id="cardNumber"
                        data-checkout="cardNumber"
                        value={cardNumber}
                        readOnly
                     />
                  </p>
                  <p>
                     <label htmlFor="cardholderName">Nome e sobrenome</label>
                     <br />
                     <input
                        type="text"
                        id="cardholderName"
                        data-checkout="cardholderName"
                        value={formPaymentInfo.cardholderName}
                        readOnly
                     />
                  </p>
                  <p>
                     <label htmlFor="cardExpirationMonth">Mês de vencimento</label>
                     <br />
                     <input
                        type="text"
                        id="cardExpirationMonth"
                        data-checkout="cardExpirationMonth"
                        value={formPaymentInfo.cardExpirationMonth}
                        readOnly
                     />
                  </p>
                  <p>
                     <label htmlFor="cardExpirationYear">Ano de vencimento</label>
                     <br />
                     <input
                        type="text"
                        id="cardExpirationYear"
                        data-checkout="cardExpirationYear"
                        value={formPaymentInfo.cardExpirationYear}
                        readOnly
                     />
                  </p>
                  <p>
                     <label htmlFor="securityCode">Código de segurança</label>
                     <br />
                     <input
                        type="text"
                        id="securityCode"
                        data-checkout="securityCode"
                        value={formPaymentInfo.securityCode}
                        readOnly
                     />
                  </p>
                  <p>
                     <label htmlFor="docType">Tipo de documento</label>
                     <select
                        id="docType"
                        data-checkout="docType"
                     ></select>
                  </p>
                  <div>
                     <label htmlFor="installments">Parcelas</label>
                     <br />
                     <select
                        id="installments"
                        className="form-control"
                        value={creditCardPaymentInfo.installments}
                        onChange={() => { }}
                     >
                        {installments.map((installment: any, idx: any) => (
                           <option key={idx} value={installment.installments}>{installment.recommended_message}</option>
                        ))}
                     </select>
                  </div>
                  <div>
                     <label htmlFor="docNumber">Número do documento</label>
                     <br />
                     <input
                        type="text"
                        id="docNumber"
                        data-checkout="docNumber"
                        value={creditCardPaymentInfo.payer.identification.number}
                        readOnly
                     />
                  </div>

                  <div>
                     <label htmlFor="issuer">Banco emissor</label>
                     <select id="issuer" name="issuer" data-checkout="issuer"></select>
                  </div>
                  <div>
                     <input
                        type="hidden"
                        name="transactionAmount"
                        id="transactionAmount"
                        value={creditCardPaymentInfo.transaction_amount}
                     />
                     <input
                        type="hidden"
                        name="paymentMethodId"
                        id="paymentMethodId"
                        disabled
                     />
                  </div>
                  {
                     cardVisible
                     &&
                     <div>
                        <input type="hidden" id="card" name="token" onChange={() => { }} value={creditCardPaymentInfo.token || ''} />
                     </div>
                  }
               </form>

            </div>
         </div>

      </div>
   )
}

export default creditCardForm
